<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Add Snake Expert</span>
          </v-flex>
          <v-flex xs12>
            <span class="subheading">Basic Info</span>
          </v-flex>
          <v-flex xs12>
            <!-- BASIC INFO -->
            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>User Name</span>
                <v-text-field outlined v-model="username" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Name</span>
                <v-text-field outlined v-model="name" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Phone</span>
                <v-text-field
                  outlined
                  v-model="phone"
                  dense
                  hide-details
                  type="number"
                  class="inputPrice"
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Password</span>
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  name="input-10-2"
                  color="#717171"
                  placeholder="Password"
                  v-model="password"
                  outlined
                  dense
                  hide-details="true"
                  class="input-group--focused"
                  @click:append="show3 = !show3"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Email</span>
                <v-text-field outlined v-model="email" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6>
                <v-btn depressed color="primary" @click="handlesubmit()">
                  <span>Submit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      username: "",
      name: "",
      phone: "",
      password: "",
      email: "",
      show1: false,
      show2: true,
      show3: false,
      show4: false,
    };
  },
  methods: {
    handlesubmit() {
      const phoneRegex = /^[0-9]{10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.name) {
        this.msg = "Please Provide Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.username) {
        this.msg = "Please Provide UserName.";
        this.showSnackBar = true;
        return;
      }
      if (!this.phone) {
        this.msg = "Please Provide Phone Number.";
        this.showSnackBar = true;
        return;
      }
      if (!phoneRegex.test(this.phone)) {
        this.msg = "Please Provide a Valid Phone Number (10 digits).";
        this.showSnackBar = true;
        return;
      }
      if (!this.email) {
        this.msg = "Please Provide Email.";
        this.showSnackBar = true;
        return;
      }
      if (!emailRegex.test(this.email)) {
        this.msg = "Please Provide a Valid Email.";
        this.showSnackBar = true;
        return;
      }
      if (!this.password) {
        this.msg = "Please Provide Password.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "post",
        url: "/user/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          username: this.username,
          phone: this.phone,
          email: this.email,
          password: this.password,
          is_expert: true,
          cpassword: this.password,
          is_rescue: "false",
          lat: "20.5937",
          lon: "78.9629",
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.name = "";
              this.username = "";
              this.email = "";
              this.password = "";
              this.phone = "";
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
  <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>